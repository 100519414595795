@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-purple-600 hover:text-purple-300;
  }
  div {
    @apply selection:bg-purple-400 selection:text-white;
  }
}

